import '../css/app.css';

import { DefineComponent } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { vMaska } from 'maska';
// import devtools from '@vue/devtools';
// @ts-ignore
import Vapor from 'laravel-vapor';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  releaseStage: import.meta.env.VITE_APP_ENV,
  appVersion: import.meta.env.PACKAGE_VERSION,
});

const bugsnagVue = Bugsnag.getPlugin('vue');

if (import.meta.env.DEV) {
  Pusher.logToConsole = true;
  //devtools.connect();
}

// @ts-ignore
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
});

window.Echo.connector.pusher.connection.bind('connected', function () {
  sessionStorage.setItem('X-Socket-Id', window.Echo.socketId());
});

window.Vapor = Vapor;

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob('./Pages/**/*.vue')
    ) as Promise<DefineComponent>,
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .directive('maska', vMaska)
      .component('AppLink', Link)
      .component('AppHead', Head)
      .component('Datepicker', Datepicker)
      // @ts-ignore
      .use(bugsnagVue)
      .use(createPinia())
      .use(plugin);

    app.config.globalProperties.route = route;

    app.mount(el);
  },
  progress: {
    includeCSS: false,
  },
});
